<template>
  <div class="containerBox">
    <div class="searchBar">
      <input class="form-control" type="text" placeholder="장치이름을 입력하세요" @input="searchData($event)" />
      <select class="selects" v-model="selected" @change="onClickTab(selected)">
        <option value="">현재 위치 선택 </option>
        <option v-for="(site, index) in siteTables" :value="index" :key="index">{{ site.title }}</option>
      </select> 
    </div>

    <div class="searchResult">
      <div class="group" v-for="(site, index) in siteTables" :key="index">
        <div class="group-item" v-for="(data, index) in site.data" :key="index">
          <div class="title"><router-link :to="`srds_device/${data.guid}`">{{ data.name }}</router-link></div> 
          <div class="underline"></div>
          <div class="iconWrapper">
            <div class="level" v-html="findLevelImg(data.level)"></div>
            <div class="battery" v-html="findBatteryImg(data)"></div>
            <div class="rfstatus" v-html="findRFImg(data)"></div>
          </div>
          <div class="imgWrapper">
            <div class="img" v-html="findCameraImg(data)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SRDSDevice from "@/views/srds/Device.vue";

export default {
  name: 'militaryDevice',
  extends: SRDSDevice,
  methods: {
  setMarkerImage(event) {
      //this.$parent.$refs.googleMap.setMarkerImage(event)
    },
  }
  }
</script>

<style dashdevice>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}

.text-box {
  position: absolute;
  height: 80%;
  color: white;
  text-align: center;
  width: 100%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 30px;
}
.dataNumber {
  margin-top: auto;
}
.containerBox {
  position: relative;
  display: inline-block;
}
.img-responsive{ 
  display: block; 
  max-width: 100%; 
  height: auto; 
} 
.threshold-text-box { 
  position: absolute; 
  height: 30%; 
  color: white; 
  text-align: center; 
  width: 100%; 
  margin: auto; 
  top: 0; 
  bottom: 0; 
  right: 0; 
  left: 0; 
  font-size: 30px;
} 
.threshold-dataNumber { 
  margin-top: auto; 
  font-size: 12px; 
  font-weight: bold;
}

/* here */
.searchBar {
  display: flex;
  margin: 15px 15px 10px;
  width: 93%;

}

.searchBar .form-control {
  margin-left: 10px;
  margin-right: 10px;
}

.searchBar > * {
  margin-left: 10px;
  font-size: 15px;
  border: 1px solid #bbbbbb;
  border-radius:5px;
  color:#494949
}

.searchBar > select {
  padding-left: 1.5%;
  font-weight: 800;
  width: 30%;
}

.group-item {
  background: #f0f0f099;
  border : 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  width: 225px;
  height: 240px;
  margin-top: 14px;
  margin-bottom: 8px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 10px;
  transition: box-shadow .3s;
  justify-content: center;
  align-items: center;
}


.level {
  margin-left: 10px;
}




</style>