<template>
  <div class="military-container">
    <CRow>
      <CCol col="12" xl="12">
        <CRow>
          <CCol col="12" xl="4">
            <CCard>
              <Summation ref="summationView" @click="onClick" />
            </CCard>
          </CCol>
          <CCol col="12" xl="5">
            <CCard>
              <EventLog
                ref="eventView"
                @update="updateSummation"
                @click="onClickEvent"
                prefix="military_"
              />
            </CCard>
          </CCol>
          <CCol col="12" xl="3">
            <CCard>
              <Weather ref="weatherView"/>
            </CCard>
          </CCol>

        </CRow>
        <CCard>
          <Device ref="deviceView" @change="onChangedSite" prefix="srds_" />
        </CCard>
      </CCol>

    </CRow>

    <EventDialog ref="eventDialog" @click="onClickEvent" />
  </div>
</template>

<script>
import EventLog from "@/views/military/EventLog";
import Device from "@/views/military/Device";
import Dashboard from "@/views/srds/Dashboard.vue";


export default {
  name: "MilitaryBoard",
  extends: Dashboard,
  components: {
    Device,
    EventLog,
  },
};
</script>