
<script>
import SRDSEvent from "@/views/srds/EventLog.vue";
export default {
  name: "MilitaryEvent",
  extends: SRDSEvent,
};
</script>


<style lang="scss" scoped>
.container {
    margin: 0;
    padding: 0;
    .header {
        display: flex;
        margin: 5px 16px;
        .title {
            width: 700px;
            font-size: 20px;
        }
    }
    .subTitle {
        display: flex;
        font-size: 12px;
        padding: 12px 16px;
        .spot {
            width: 250px;
        }
        .content {
            width: 300px;
        }
        .date {
            width: 230px;
        }
        .remove {
            width: 65px;
        }
    }
    .eventWrapper {
        height: 180px;
        overflow: auto;
        .event {
            background-color: #fff;
            display: flex;
            height: 60px;
            border-top: 1px dotted rgb(179, 179, 179);
            padding: 12px 16px;
            align-items: center;
            .spot {
                // background-color: aquamarine;
                display: flex;
                width: 250px;
                height: 39px;
                align-items: center;
                .device_title {
                    a {
                      font-size: 12px;
                      display: block;
                      color: gray
                    }
                    margin-left: 10px;
                    .device_address {
                        font-weight: 700;
                        font-size: 14px;
                    }
                    .device_name {
                        font-size: 12px;
                    }
                }
            }
            .content {
                height: 39px;
                width: 300px;
            }
            .date {
                font-weight: 500;
                height: 39px;
                width: 230px;
            }
            .remove {
                cursor: pointer;
                text-align: center;
                width: 55px;
            }
        }
    }
}
.eventWrapper::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}
.eventWrapper::-webkit-scrollbar-track {
  background-color: #fff;
}
.eventWrapper::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 16px;
  border: 4xp solid #fff;
}

@media (max-width: 414px) {
  .container {
    font-size: 10px;
  }
}
</style>